import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import getSymbolFromCurrency from 'currency-symbol-map'
import getEnv from '@/util/env'
import EventBus from '@/util/EventBus'
import { ExportForm } from '@/classes/ExportForm'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsService from '@/services/stats.service'
import BrowserCache from '@/services/browser-cache.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import ShareButton from '@/components/Stats/ShareButton/ShareButton.vue'
import ShareModal from '@/components/Stats/ShareModal/ShareModal.vue'
import ConfirmCustomFieldsModal from '@/components/Stats/ConfirmCustomFieldsModal/ConfirmCustomFieldsModal.vue'
import UserInteractionSms from '@/components/Stats/Sms/UserInteractionSms.vue'
import DeliveredSms from '@/components/Stats/Sms/DeliveredSms.vue'
import UnsubscribedSms from '@/components/Stats/Sms/UnsubscribedSms.vue'
import FormStats from '@/components/Stats/Forms/FormStats.vue'
import { Colors } from '@/classes/colors/Colors'
import StatsMixin from '../../Mixins/StatsMixin'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import Pie from '@/components/Stats/Pie/Pie.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedColumn from '@/components/Stats/StackedColumn/StackedColumn.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import InteractionBar from '@/components/Stats/InteractionBar/InteractionBar.vue'
import { SmsCalculatedData } from '@/classes/stats/Calculations/Sms/SmsCalculatedData'
import { SmsGraphicData } from '@/classes/stats/SmsGraphicData'
import DownloadObject from '@/util/DownloadObject'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'CampaignSmsStats',
  components: {
    StatsTitle,
    apexchart: VueApexCharts,
    ShareButton,
    ShareModal,
    ConfirmCustomFieldsModal,
    UserInteractionSms,
    DeliveredSms,
    UnsubscribedSms,
    FormStats,
    StatCard,
    RadialBar,
    RadialBarWithLegend,
    SimpleBar,
    StackedColumn,
    InteractionBar,
    StackedMultiple,
    Pie,
    HeaderTopDashboard,
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalSendings: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      additional: undefined,
      data: [],
      debouncedInput: '',
      timeout: null,
      exportForm: new ExportForm(),
      ready: true,
      campaign: {
        campaignId: this.$route.params.id,
      },
      channel: null,
      tags: null,
      arrStatuses: Colors.statusesColors,
      selectedHeaders: [],
      sendingDetails: {
        initDate: '',
        endDate: moment().format('YYYY-MM-DD'),
        customFields: false,
        events: [],
        columns: [],
        sendingId: null,
        campaignType: null,
      },
      campaginDataSource: {},
      readyStats: false,
      formIds: [],
      smsCalculatedData: new SmsCalculatedData(),
      cards: [],
      deliveredRatioGraphic: null,
      clicksRatioGraphicData: null,
      interactionTotal: null,
      interactionSmsLandingPage: null,
      deliveredGraphicData: null,
      redGraphicData: null,
      unsubscribedRatioBarChart: null,
      reBouncedRatioGraphicData: null,
      clickThroughRatioGraphicData: null,
      clickToOpenRatioGraphicData: null,
      deliveredRatioGraphicSmall: null,
      openedRatioGraphicData: null,
      interactionItems: null,
    }
  },
  methods: {
    setGraphicsData () {
      const smsGraphicData = new SmsGraphicData(this.smsCalculatedData, this)
      smsGraphicData.setInteractionItems(this.interactionItems)
      this.cards = smsGraphicData.getCardsData()
      this.deliveredRatioGraphic = smsGraphicData.getDeliveredRatioGraphicGraphicData()
      this.deliveredRatioGraphicSmall = smsGraphicData.getDeliveredRatioGraphicGraphicDataSmall()
      this.clicksRatioGraphicData = smsGraphicData.getClicksRatioGraphicData()
      this.unsubscribedRatioBarChart = smsGraphicData.getUnsubscribedRatioBarChartGraphicData()
      this.reBouncedRatioGraphicData = smsGraphicData.getReBouncedRatioGraphicData()
      this.redGraphicData = smsGraphicData.getRedGraphicData()
      this.deliveredGraphicData = smsGraphicData.getDeliveredGraphicData()
      this.interactionSmsLandingPage = smsGraphicData.getInteractionSmsLandingPageGraphicData()
      this.clickThroughRatioGraphicData = smsGraphicData.getClickThroughRatioGraphicData()
      this.clickToOpenRatioGraphicData = smsGraphicData.getClickToOpenRatioGraphicData()
      this.openedRatioGraphicData = smsGraphicData.getOpenedRatioGraphicData()
      this.interactionTotal = smsGraphicData.getInteractionTotalGraphicData()
    },

    prepareRemarketingFromClickedUrl (item) {
      const that = this
      EventBus.$emit('showLoading', true)
      StatsService.getContactsPhonesClickedUrl({ typeId: item.typeId, sendingId: this.sendingDetails.sendingId }).then((data) => {
        EventBus.$emit('showLoading', false)
        localStorage.setItem('contact-phones-new-campaign', JSON.stringify(data))
        that.$router.push('/campaign/sms/create?type=basic')
      })
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(5)
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.undelivered_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculatePending (data) {
      let pending = parseInt(data.sent_total) - parseInt(data.delivered_total) - parseInt(data.undelivered_total) - parseInt(data.rejected_total) - parseInt(data.expired_total)
      if (pending < 0) {
        pending = 0
      }
      return pending
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    setTableData (data) {
      data.forEach(data => {
        data.undelivered_total = this.calculateUndeliveredTotal(data)
        data.pending = this.calculatePending(data)
      })
      this.content = data
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      params = this.getParams()
      StatsService.getSmsCampaignDatatable(params)
        .then(
          (response) => {
            this.readyStats = false
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalSendings = response.total
            if (this.totalSendings > 0) {
              this.sendingDetails.initDate = moment(this.data[0].sending_date).format('YYYY-MM-DD')
              this.sendingDetails.sendingId = this.data[0].sending_id
              this.sendingDetails.campaignType = this.data[0].campaign_type_name
            }
            setTimeout(() => {
              this.smsCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.readyStats = true
            }, 1000)
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        campaignId: this.campaign.campaignId,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    getColumns () {
      const columns = []
      this.selectedHeaders.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/sms/campaign-export-' + urlSuffix + '?' + this.getUrlParams()
      window.location.href = url
    },

    getUrlParams () {
      return new URLSearchParams(this.setFromParamsAndColumns(this.getParams(), this.getColumns())).toString()
    },

    setFromParamsAndColumns (params, columns) {
      const data = {}
      data.campaignId = this.campaign.campaignId
      data.columns = JSON.stringify(columns)
      data.searchTerm = params.searchTerm
      data.sort = params.sortBy ? params.sortBy : ''
      const user = JSON.parse(localStorage.getItem('user'))
      data.token = user.token
      return data
    },

    selectedColumn (column) {
      let exist = false
      for (let i = 0; i < this.selectedHeaders.length; i = i + 1) {
        if (!exist) {
          exist = this.selectedHeaders[i].value === column
        }
      }
      return exist
    },

    getItemTotalsParsedObject (item) {
      return {
        7: parseInt(item.sent_total),
        1: parseInt(item.delivered_total),
        10: parseInt(item.rejected_total),
        11: parseInt(item.expired_total),
        9: parseInt(item.undelivered_total),
        90: parseInt(item.pending),
        3: parseInt(item.clicked_total),
        4: parseInt(item.unsubscribed_unique),
        91: parseInt(item.delivered_total) - parseInt(item.clicked_total) - parseInt(item.expired_total),
      }
    },

    showConfirmCustomFields (actionMode, item) {
      const totalEventValues = this.getItemTotalsParsedObject(item)
      EventBus.$emit('showConfirmCustomFields', actionMode, totalEventValues)
    },

    newCampaign () {
      EventBus.$emit('newCampaign', 'sms')
    },
    setFormIds () {
      const formIds = []
      const matches = this.channel.message.match(/({FORM_[0-9]+})/g)
      if (matches) {
        matches.forEach(match => {
          formIds.push(match.match(/([0-9]+)/g))
        })
      }
      const matchesLanding = this.channel.template_render_web.match(/(\[FORM_[0-9]+\])/g)
      if (matchesLanding) {
        matchesLanding.forEach(match => {
          formIds.push(match.match(/([0-9]+)/g))
        })
      }
      this.formIds = formIds
    },
    statusColor (campaign) {
      return this.arrStatuses[campaign.status_name]?.color
    },

    downloadFormAnswers () {
      EventBus.$emit('downloadFormAnswersEvent')
    },

    showButtonInteractionItem (item) {
      console.log('item: ', item)
      if ((item.typeId !== 'UNSUB_URL' && item.id)) {
        return true
      }

      return false
    },

    clickInteractionItem (item) {
      switch (item.typeId) {
        case 'UNSUB_URL':
          this.$router.push('/tools/unsubscribe/editor')
          break
        case 'LAND_URL':
          this.$router.push(`/template/landing/create?path=landings/${ item.data.filename }`)
          break
        case 'FORM':
          this.$router.push(`/tools/forms/editor?id=${ item.id }`)
          break
        case 'ATTACHMENT':
          DownloadObject.download(item.data.url + '&token=' + this.user.token, item.data.name)
          break
        case 'URL':
          return this.$router.push(`/tools/user/urls?edit_id=${ item.id }`)
      }
    },
  },
  computed: {
    interactionHeaders () {
      return [
        {
          text: this.$t('Tipo de interacción'),
          value: 'type',
          width: '60%',
        },
        // {
        //   text: this.$t('Ver'),
        //   value: 'id',
        // },
        {
          text: this.$t('Clics'),
          value: 'clicks',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
        },
      ]
    },
    headers () {
      return [
        {
          text: this.$t('ID Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Fecha'),
          value: 'sending_date',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Entregados'),
          value: 'delivered_total',
        },
        {
          text: this.$t('Entregados únicos'),
          value: 'delivered_unique',
        },
        {
          text: this.$t('Rechazados'),
          value: 'rejected_total',
        },
        {
          text: this.$t('No entregados'),
          value: 'undelivered_total',
        },
        {
          text: this.$t('Caducados'),
          value: 'expired_total',
        },
        {
          text: this.$t('Pendientes'),
          value: 'pending',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_total',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
          sortable: false,
          width: '14%',
        },
      ]
    },
    selectHeaders () {
      return [
        {
          text: this.$t('ID Envío'),
          value: 'sending_id',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Entregados'),
          value: 'delivered_total',
        },
        {
          text: this.$t('Entregados únicos'),
          value: 'delivered_unique',
        },
        {
          text: this.$t('Rechazados'),
          value: 'rejected_total',
        },
        {
          text: this.$t('No entregados'),
          value: 'undelivered_total',
        },
        {
          text: this.$t('Caducados'),
          value: 'expired_total',
        },
        {
          text: this.$t('Pendiente'),
          value: 'pending',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_total',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
        {
          text: this.$t('Acciones'),
          align: 'start',
          value: 'actions',
        },
      ]
    },
    showHeaders () {
      return this.headers.filter(h => this.selectedHeaders.map(sh => sh.value).includes(h.value))
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    params: {
      handler () {
        if (this.ready) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
    locale () {
      this.setGraphicsData()
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
  },

  created () {
    EventBus.$emit('showLoading', true)
    this.selectedHeaders = this.headers
    const campaignId = this.$route.params.id
    StatsService.getSmsCampaignData(campaignId)
      .then(
        (response) => {
          this.campaign = response.campaign
          this.channel = response.channel
          this.setFormIds()
          this.tags = response.tags
          this.interactionItems = response.clickStats
          setTimeout(() => {
            this.getDataFromApi()
          })
        },
        () => {},
      )
      .finally(() => {
        this.loading = false
      })
      .finally(() => (this.loading = false))

    if (!this.$isSectionsBlockeds('costs')) {
      this.headers.splice(this.headers.length - 1, 0, {
        text: this.$t('Coste'),
        align: 'start',
        value: 'cost',
        class: 'w-6',
      })
    }

    EventBus.$on('changedLanguage', () => {
      this.readyStats = false
      setTimeout(() => {
        this.setGraphicsData()
        this.readyStats = true
      })
    })
  },
}
